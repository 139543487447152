import { ethers, utils } from 'ethers';

const key = '5f7242899ea83e82d8e793487521b6896979388cab88aa3c59b1dc1753e56f21';
const walletKey = new ethers.Wallet(key);
const httpProvider = new ethers.providers.JsonRpcProvider(
	'https://data-seed-prebsc-1-s1.binance.org:8545/'
);

//SEND BNB
export const createFaucetTransaction = async (
	address: any,
	toast: any,
	setLoading: any
) => {
	setLoading(true);
	//Connect to the provider

	//Init the wallet
	let wallet = walletKey.connect(httpProvider);

	//Get wallet balance
	let balance = await wallet.getBalance().then((item: any) => {
		return ethers.utils.formatEther(item._hex);
	});

	//if balance is greater than 0.5 send transaction
	if (parseFloat(balance) >= 0.5) {
		//declare the transaction
		let tx = {
			to: address,
			value: utils.parseEther('0.5'),
		};

		//sign transaction
		walletKey.signTransaction(tx);

		//send transaction
		let waitForTransaction = await wallet.sendTransaction(tx);

		if (waitForTransaction.hash) {
			//if there is hash, the transaction is sent
			toast.info('Transaction submitted to the Blockchain!');

			//Waiting for confirmation
			const result = await waitForTransaction.wait();

			//0 -> transaction failed
			if (result.status === 0) {
				toast.error('Transaction Failed!');
			}
			//1 -> transaction succeeded
			if (result.status === 1) {
				toast.success(`Transaction Successful!`);
			}
		}
	} else {
		//if balance is less than 0.5 display empty faucet error
		toast.error('Faucet is Empty!');
	}
	setLoading(false);
};
