//Ethers connection to blockchain
import { ethers } from 'ethers';
import { busdAbi, stablecoinAbi } from './ABIs';

import { ContractAddress } from './enum';

// changes network and adds tokens to metamask
export const metamaskHandler = async (provider: any) => {
	const stablecoinAddress = ContractAddress.Stablecoin;
	const stablecoinSymbol = 'MTK';

	const busdAddress = ContractAddress.BUSD;
	const busdSymbol = 'BUSD';

	const tokenDecimals = 18;

	let wasAddedStablecoin = window.localStorage.getItem('stablecoin');
	let wasAddedBusd = window.localStorage.getItem('busd');

	//ADD BSC TESTNET
	//TODO IN THE FUTURE -> BSC mainnet settings
	//refer to SIN Documentation for metamask methods
	try {
		await provider.provider.request({
			method: 'wallet_addEthereumChain',
			params: [
				{
					chainId: '0x61',
					chainName: 'Binance Smart Chain Testnet',
					nativeCurrency: {
						name: 'Binance Coin',
						symbol: 'BNB',
						decimals: 18,
					},
					rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
					blockExplorerUrls: ['https://testnet.bscscan.com/'],
				},
			],
		});
	} catch (error) {
		console.log(error);
	}

	//ADD Stablecoin
	if (!wasAddedStablecoin) {
		try {
			let wasAdded = await provider.provider.request({
				method: 'wallet_watchAsset',
				params: {
					type: 'ERC20',
					options: {
						address: stablecoinAddress,
						symbol: stablecoinSymbol,
						decimals: tokenDecimals,
					},
				},
			});
			if (wasAdded) {
				window.localStorage.setItem('stablecoin', 'true');
			} else {
				window.localStorage.setItem('stablecoin', 'false');
			}
		} catch (error) {
			console.log(error);
		}
	}
	//ADD BUSD
	if (!wasAddedBusd) {
		try {
			let wasAdded = await provider.provider.request({
				method: 'wallet_watchAsset',
				params: {
					type: 'ERC20',
					options: {
						address: busdAddress,
						symbol: busdSymbol,
						decimals: tokenDecimals,
					},
				},
			});
			if (wasAdded) {
				window.localStorage.setItem('busd', 'true');
			} else {
				window.localStorage.setItem('busd', 'false');
			}
		} catch (error) {
			console.log(error);
		}
	}
};

// returns token balances
export const getBalances = async (walletAddress: any, signer: any) => {
	//BUSD TOKEN BALANCE
	const busdToken = new ethers.Contract(ContractAddress.BUSD, busdAbi, signer);

	//STABLECOIN TOKEN
	const stablecoin = new ethers.Contract(
		ContractAddress.Stablecoin,
		stablecoinAbi,
		signer
	);

	//get busd balance
	const busdBalance = await getBalance(busdToken, walletAddress);

	//get allowance for our token
	const busdAllowance = await getAllowance(
		busdToken,
		walletAddress,
		ContractAddress.Stablecoin
	);

	//get stablecoin balance
	const stablecoinBalance = await getBalance(stablecoin, walletAddress);

	return {
		busdBalance: busdBalance,
		busdAllowance: busdAllowance,
		stablecoinBalance: stablecoinBalance,
	};
};

//get Balance
export const getBalance = async (
	contract: ethers.Contract,
	address: string
): Promise<string> => {
	return await contract.balanceOf(address).then((item: any) => {
		return ethers.utils.formatEther(item._hex);
	});
};

//get Allowance
export const getAllowance = async (
	contract: ethers.Contract,
	address: string,
	contractAddress: string
): Promise<string> => {
	return await contract
		.allowance(address, contractAddress)
		.then((item: any) => {
			return ethers.utils.formatEther(item._hex);
		});
};
