import { useState, createContext } from 'react';

// Interfaces
interface ContextProps {
	wallet: any;
	setWallet: any;
	walletAddress: any;
	setWalletAddress: any;
	network: any;
	setNetwork: any;
	balance: any;
	setBalance: any;
	onboard: any;
	setOnboard: any;
	provider: any;
	setProvider: any;
	signer: any;
	setSigner: any;
	stablecoinBalance: any;
	setStablecoinBalance: any;
	busdBalance: any;
	setBusdBalance: any;
	allowance: any;
	setAllowance: any;
	faucetBnb: any;
	setFaucetBnb: any;
}

interface ProviderProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}

//Export Onboard Context
export const OnboardContext = createContext<Partial<ContextProps>>({});

const OnboardContextProvider = ({ children }: ProviderProps) => {
	const [wallet, setWallet] = useState<any>();
	const [walletAddress, setWalletAddress] = useState<any>();
	const [network, setNetwork] = useState<number>();
	const [balance, setBalance] = useState<number>();

	//Onboard state
	const [onboard, setOnboard] = useState<any>();

	//Web3 provider
	const [provider, setProvider] = useState<any>();
	const [signer, setSigner] = useState<any>();

	const [stablecoinBalance, setStablecoinBalance] = useState<any>('');
	const [busdBalance, setBusdBalance] = useState<any>('');
	const [allowance, setAllowance] = useState<any>('');

	//Faucet balance
	const [faucetBnb, setFaucetBnb] = useState<any>('');
	return (
		<OnboardContext.Provider
			value={{
				wallet,
				setWallet,
				walletAddress,
				setWalletAddress,
				network,
				setNetwork,
				balance,
				setBalance,
				onboard,
				setOnboard,
				provider,
				setProvider,
				signer,
				setSigner,
				stablecoinBalance,
				setStablecoinBalance,
				busdBalance,
				setBusdBalance,
				allowance,
				setAllowance,
				faucetBnb,
				setFaucetBnb,
			}}>
			{children}
		</OnboardContext.Provider>
	);
};

export default OnboardContextProvider;
