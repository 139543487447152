import React from 'react';
import { Input, Card, Button } from 'semantic-ui-react';

function BuyToken({
	approve,
	exchange,
	amount,
	isDisabled,
	inputChangeHandler,
	isDisabledEnable,
}: any) {
	return (
		<div>
			<Card centered style={{ marginBottom: '10px' }}>
				<Card.Content>
					<Card.Header>Buy Our Token</Card.Header>
				</Card.Content>
				<Card.Content>
					<Input
						fluid
						type='text'
						onChange={inputChangeHandler}
						value={amount}
						placeholder='Enter Stablecoin or BUSD amount'
					/>
				</Card.Content>

				<Card.Content extra>
					<div className='ui two buttons'>
						<Button
							disabled={isDisabledEnable()}
							color='teal'
							onClick={approve}>
							Enable
						</Button>
						<Button disabled={isDisabled()} color='blue' onClick={exchange}>
							Exchange
						</Button>
					</div>
				</Card.Content>
			</Card>
		</div>
	);
}

export default BuyToken;
