import React from 'react';
import Onboard from './Onboard';
import { Container } from 'semantic-ui-react';

interface ILayout {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
}
const Layout = ({ children }: ILayout) => {
	return (
		<Container style={{ padding: '10px' }}>
			<Onboard />
			{children}
		</Container>
	);
};

export default Layout;
