import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, Table, Popup, Icon } from 'semantic-ui-react';

interface OnboardProps {
	functionHandler: any;
	buttonText: string;
	warning?: string;
	busdBalance?: any;
	stablecoinBalance?: any;
	allowance?: any;
	network?: any;
	balance?: any;
	activePage?: any;
	location?: any;
}

const Onboard: React.FC<OnboardProps> = ({
	functionHandler,
	buttonText,
	busdBalance,
	warning,
	stablecoinBalance,
	balance,
	allowance,
	network,
	activePage,
	location,
}): any => {
	return (
		<React.Fragment>
			<Menu stackable>
				<Menu.Item as={Link} to='/' active={location.pathname === '/'}>
					Buy Our Token
				</Menu.Item>
				<Menu.Item as={Link} to='/sell' active={location.pathname === '/sell'}>
					Sell Our Token
				</Menu.Item>
				<Menu.Item
					as={Link}
					to='/faucet'
					active={location.pathname === '/faucet'}
				>
					BNB Faucet
				</Menu.Item>

				<Menu.Menu position='right'>
					<Menu.Item>
						<Button size='tiny' fluid primary onClick={functionHandler}>
							{buttonText}
						</Button>
					</Menu.Item>
				</Menu.Menu>
			</Menu>

			{busdBalance && (
				<>
					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell colSpan='3'>BALANCES</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell width={5}>BNB Balance</Table.Cell>
								<Table.Cell>{balance} BNB</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell width={5}>BUSD Balance</Table.Cell>
								<Table.Cell>{busdBalance} BUSD</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Stablecoin Balance</Table.Cell>
								<Table.Cell>{stablecoinBalance} Tokens</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>

					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell colSpan='3'>ALLOWANCE</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell width={5}>
									Stablecoin allowance to spend BUSD{'	'}
									<Popup
										trigger={<Icon name='question' color='blue' />}
										content='In order to get the stablecoin, first you need to enable the Stablecoin contract to spend some BUSD in your name.'
										inverted
									/>
								</Table.Cell>
								<Table.Cell>{allowance} BUSD</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>

					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell colSpan='3'>FAUCETS</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>
									Binance Smart Chain Testnet faucet (USE ONLY FOR BUSD!){'	'}
									<Popup
										trigger={<Icon name='question' color='blue' />}
										content='A place where you can get BUSD for the testnet'
										inverted
									/>
								</Table.Cell>
								<Table.Cell>
									<a
										target='_blank'
										rel='noreferrer'
										href='https://testnet.binance.org/faucet-smart'
									>
										https://testnet.binance.org/faucet-smart
									</a>
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</>
			)}
			{network !== 97 && (
				<p>Please connect to the Binance Smart Chain Testnet</p>
			)}
			{warning && <p>{warning}</p>}
		</React.Fragment>
	);
};
export default Onboard;
