//after bsc mainnet deployment change Selected to 56 (bsc mainnet network id)
export enum NetworkID {
	Selected = 97,
	Mainnet = 1,
	Ropsten = 3,
	Rinkeby = 4,
	Goerli = 5,
	Kovan = 42,
	BinanceTestNet = 97,
	BSCMainnet = 56,
}

//change BUSD and Stablecoin address after mainnet deployment
export enum ContractAddress {
	BUSD = '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
	Stablecoin = '0x53fB7D637DD04f32c1ef8114bb895d1bd2464Fc0',
}
