import { useContext, useState } from 'react';

//API context
import { OnboardContext } from '../../../context/OnboardContext';

//Toastify notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SellToken from './SellToken';
import Layout from '../../general/Layout';

import { ContractAddress, NetworkID } from '../../../ethers/enum';
import { stablecoinAbi } from '../../../ethers/ABIs';

//Ethers connection to blockchain
import { ethers } from 'ethers';

function Index() {
	const [amount, setAmount] = useState<any>('');

	const { signer, network, walletAddress, stablecoinBalance, balance } =
		useContext(OnboardContext);

	//Handle input change
	const inputChangeHandler = (e: any) => {
		if (e.target.value.match('^[0-9]*[.]?[0-9]{0,18}$') != null) {
			setAmount(e.target.value);
		}
	};

	//Disable exchange button
	const isDisabled = () => {
		if (
			!amount ||
			parseFloat(amount) > parseFloat(stablecoinBalance) ||
			parseFloat(amount) === 0 ||
			parseFloat(ethers.utils.formatEther(balance)) <= 0.000318
		) {
			return true;
		}
	};

	const sellTokens = async () => {
		let stablecoin = new ethers.Contract(
			ContractAddress.Stablecoin,
			stablecoinAbi,
			signer
		);

		const waitForTransaction = await stablecoin.burnTokens(
			ethers.utils.parseEther(amount)
		);

		if (waitForTransaction.hash) {
			toast.info('Transaction submitted to the Blockchain!');
			const result = await waitForTransaction.wait();

			if (result.status === 0) {
				toast.error('Transaction Failed!');
			}
			if (result.status === 1) {
				toast.success(`Transaction Successful!`);
			}
		}
	};

	return (
		<Layout>
			{walletAddress && network === NetworkID.BinanceTestNet ? (
				<SellToken
					exchange={sellTokens}
					amount={amount}
					inputChangeHandler={inputChangeHandler}
					isDisabled={isDisabled}
				/>
			) : null}
			<ToastContainer position='bottom-right' />
		</Layout>
	);
}

export default Index;
