import React, { useEffect, useContext, useState } from 'react';
//API context
import { OnboardContext } from '../../../context/OnboardContext';
import getSigner from '../../../ethers/signer';
import Onboard from './Onboard';

//Ethers connection to blockchain
import { ethers } from 'ethers';

import { NetworkID } from '../../../ethers/enum';
import { initOnboard } from '../../../ethers/services';

import { metamaskHandler, getBalances } from '../../../ethers/utils';

import { useLocation } from 'react-router-dom';

const Index = () => {
	const {
		wallet,
		setWallet,
		walletAddress,
		setWalletAddress,
		network,
		setNetwork,
		balance,
		setBalance,
		onboard,
		setOnboard,
		provider,
		setProvider,
		signer,
		setSigner,
		stablecoinBalance,
		setStablecoinBalance,
		busdBalance,
		setBusdBalance,
		allowance,
		setAllowance,
	} = useContext(OnboardContext);

	const init = new Date();
	const [date, setDate] = useState(init);

	const tick = () => {
		setDate(new Date());
	};

	//location
	const location = useLocation();

	//Initialize Onboard
	useEffect(() => {
		const onboard = initOnboard({
			address: setWalletAddress,
			network: setNetwork,
			balance: setBalance,
			wallet: async (wallet: any) => {
				if (wallet.provider) {
					const ethersProvider = new ethers.providers.Web3Provider(
						wallet.provider,
						'any'
					);
					ethersProvider.on('network', (newNetwork, oldNetwork) => {
						// When a Provider makes its initial connection, it emits a "network"
						// event with a null oldNetwork along with the newNetwork. So, if the
						// oldNetwork exists, it represents a changing network
						if (oldNetwork) {
							window.location.reload();
						}
					});

					setWallet(wallet);
					setProvider(ethersProvider);
					setSigner(getSigner(ethersProvider));
					window.localStorage.setItem('selectedWallet', wallet.name);
				} else {
					setProvider(null);
				}
			},
		});

		setOnboard(onboard);
	}, [
		setWallet,
		setProvider,
		setWalletAddress,
		setNetwork,
		setOnboard,
		setBalance,
		setSigner,
	]);

	//Load previously selected wallet wallet
	useEffect(() => {
		const previouslySelectedWallet =
			window.localStorage.getItem('selectedWallet');
		if (previouslySelectedWallet && onboard) {
			onboard.walletSelect(previouslySelectedWallet);
		}
	}, [onboard]);

	useEffect(() => {
		const timerID = setInterval(() => tick(), 3000);

		if (
			onboard &&
			network === NetworkID.BinanceTestNet &&
			signer &&
			walletAddress
		) {
			(async () => {
				const balances = await getBalances(walletAddress, signer);

				setAllowance(balances.busdAllowance);
				setBusdBalance(balances.busdBalance);
				setStablecoinBalance(balances.stablecoinBalance);
			})();
		}
		return () => {
			clearInterval(timerID);
		};
	}, [
		network,
		onboard,
		signer,
		walletAddress,
		date,
		setAllowance,
		setBusdBalance,
		setStablecoinBalance,
	]);

	useEffect(() => {
		if (provider) {
			(async () => {
				await metamaskHandler(provider);
			})();
		}
	}, [provider]);

	//On wallet select
	const walletSelectHandler = async () => {
		if (await onboard.walletSelect()) {
			await onboard.walletCheck();
		}
	};

	const walletResetHandler = async () => {
		onboard.walletReset();
		window.localStorage.removeItem('selectedWallet');
	};

	return !wallet || !walletAddress || !provider || !balance ? (
		<Onboard
			functionHandler={walletSelectHandler}
			buttonText='Connect Wallet'
			warning='No wallet connected!'
			location={location}
		/>
	) : (
		<Onboard
			functionHandler={walletResetHandler}
			buttonText={
				walletAddress.substring(0, 5) + '...' + walletAddress.substring(36, 42)
			}
			busdBalance={busdBalance}
			stablecoinBalance={stablecoinBalance}
			allowance={allowance}
			network={network}
			balance={ethers.utils.formatEther(balance)}
			location={location}
		/>
	);
};

export default Index;
