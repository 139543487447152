import React from 'react';
import './App.css';
import Routes from './routes/Routes';
import OnboardContextProvider from './context/OnboardContext';
//test push
function App() {
	return (
		<OnboardContextProvider>
			<Routes />
		</OnboardContextProvider>
	);
}

export default App;
