import { useContext, useState } from 'react';
import { Card, Button } from 'semantic-ui-react';

//API context
import { OnboardContext } from '../../../context/OnboardContext';
import Layout from '../../general/Layout';

//Enum
import { NetworkID } from '../../../ethers/enum';
import { createFaucetTransaction } from '../../../ethers/faucet';

//Toastify notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Index() {
	const { walletAddress, network } = useContext(OnboardContext);
	const [loading, setLoading] = useState<boolean>(false);
	return (
		<Layout>
			{walletAddress && network === NetworkID.BinanceTestNet ? (
				<Card centered>
					<Card.Content>
						<Card.Header>GET 0.5 BNB!</Card.Header>
					</Card.Content>
					<Card.Content>
						<Button
							loading={loading}
							disabled={loading}
							primary
							fluid
							onClick={() =>
								createFaucetTransaction(walletAddress, toast, setLoading)
							}>
							Request
						</Button>
						<ToastContainer position='bottom-right' />
					</Card.Content>
				</Card>
			) : null}
		</Layout>
	);
}

export default Index;
