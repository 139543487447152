import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import BuyToken from '../Containers/pages/BuyToken';
import SellToken from '../Containers/pages/SellToken';
import Faucet from '../Containers/pages/Faucet';

const Routes = () => {
	return (
		<Router>
			<Switch>
				<Route exact path='/' component={BuyToken} />
				<Route exact path='/sell' component={SellToken} />
				<Route exact path='/faucet' component={Faucet} />
			</Switch>
		</Router>
	);
};

export default Routes;
