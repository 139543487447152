import Onboard from 'bnc-onboard';
import { NetworkID } from './enum';

//Init Onboard
export const initOnboard = (subscriptions: any): any => {
	return Onboard({
		hideBranding: true,
		networkId: NetworkID.Selected,
		subscriptions,
		walletSelect: {
			wallets: [{ walletName: 'metamask' }],
		},
	});
};
