import React, { useState, useContext } from 'react';

//Ethers connection to blockchain
import { ethers } from 'ethers';

//API context
import { OnboardContext } from '../../../context/OnboardContext';
import BuyToken from './BuyToken';
import Layout from '../../general/Layout';

//Enum
import { ContractAddress, NetworkID } from '../../../ethers/enum';

//Import contract ABIs
import { busdAbi, stablecoinAbi } from '../../../ethers/ABIs';

//Toastify notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Index() {
	const { signer, walletAddress, network, busdBalance, allowance, balance } =
		useContext(OnboardContext);

	const [amount, setAmount] = useState<any>('');

	//Handle input change
	const inputChangeHandler = (e: any) => {
		if (e.target.value.match('^[0-9]*[.]?[0-9]{0,18}$') != null) {
			setAmount(e.target.value);
		}
	};

	//Disable exchange button
	const isDisabledExchange = () => {
		if (
			!amount ||
			parseFloat(amount) > parseFloat(allowance) ||
			parseFloat(amount) === 0 ||
			parseFloat(amount) > parseFloat(busdBalance) ||
			parseFloat(ethers.utils.formatEther(balance)) <= 0.000318
		) {
			return true;
		}
	};
	//Disable enable button
	const isDisabledEnable = () => {
		if (
			!amount ||
			parseFloat(ethers.utils.formatEther(balance)) <= 0.000318 ||
			parseFloat(amount) === 0
		) {
			return true;
		}
	};

	//Approve Stablecoin to spend BUSD
	const approve = async () => {
		const busdContract = new ethers.Contract(
			ContractAddress.BUSD,
			busdAbi,
			signer
		);

		const waitForTransaction = await busdContract.approve(
			ContractAddress.Stablecoin,
			ethers.utils.parseEther(amount)
		);

		if (waitForTransaction.hash) {
			toast.info('Transaction submitted to the Blockchain!');
			const result = await waitForTransaction.wait();

			if (result.status === 0) {
				toast.error('Transaction Failed!');
			}
			if (result.status === 1) {
				toast.success(`Transaction Successful!`);
			}
		}
	};

	//After approval, user can buy the stablecoin
	const buyStablecoin = async () => {
		let stablecoin = new ethers.Contract(
			ContractAddress.Stablecoin,
			stablecoinAbi,
			signer
		);

		const waitForTransaction = await stablecoin.mintAndTransfer(
			ethers.utils.parseEther(amount)
		);

		if (waitForTransaction.hash) {
			toast.info('Transaction submitted to the Blockchain!');
			const result = await waitForTransaction.wait();

			if (result.status === 0) {
				toast.error('Transaction Failed!');
			}
			if (result.status === 1) {
				toast.success(`Transaction Successful!`);
			}
		}
	};

	return (
		<Layout>
			{walletAddress && network === NetworkID.BinanceTestNet ? (
				<React.Fragment>
					<BuyToken
						approve={approve}
						exchange={buyStablecoin}
						amount={amount}
						inputChangeHandler={inputChangeHandler}
						isDisabled={isDisabledExchange}
						isDisabledEnable={isDisabledEnable}
					/>
					<ToastContainer position='bottom-right' />
				</React.Fragment>
			) : null}
		</Layout>
	);
}

export default Index;
